<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 100 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              people
            </v-icon>
            Followers
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Followers
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                volume_down
              </v-icon>

              Influencer
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Identify influencers by number of their followers.
              </span>
            </v-tooltip>
          </div>

          <followers-selector
            class="mt-3"
            v-model="form.from"
            label="From"
            :max-value="maxValue + 1"
            @change="submitForm"
          />

          <followers-selector
            class="mt-3"
            v-model="form.to"
            label="To"
            @change="submitForm"
            :min-value="form.from"
            :disabled="isToDisabled"
          />
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import the block level component for selecting values
const FollowersSelector = () => import(/* webpackChunkName: "followers-selector" */ "@/blocks/common/selectors/FollowersSelector.vue")

// used throughout the code
const filterName = "followers"

const originalForm = () => ({
  from: "1000",
  to: Infinity.toString()
})

export default {
  name: "FilterFollowers",

  props: {
    moduleName: {
      type: String,
      required: true
    },

    isFilterApplied: {
      type: Boolean,
      required: true
    },

    moduleId: {
      type: [Symbol, String, Number],
      required: false,
      default: null
    },

    maxValue: {
      type: Number,
      required: false,
      default: 1_000_000
    }
  },

  components: {
    FollowersSelector,
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
  }),

  computed: {
    isToDisabled() {
      return parseInt(this.form.from, 10) === this.maxValue
    },

    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },
  },

  validations: {
    form: {}
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText() {
      return "Followers: " +
        (this.isToDisabled || this.form.to === Infinity.toString() ? `>${nFormatter(this.form.from)}` : `${nFormatter(this.form.from)}-${nFormatter(this.form.to)}`)
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm() {
      await this.$v.form.$touch()

      if (this.$v.form.$anyError) return

      // if to is to be shown but to's value is falsy
      if (!this.isToDisabled && !this.form.to) {
        this.form.to = this.maxValue.toString()
      }

      // if there's some value, then update the filter
      this.$emit("submit", {
        type: filterName,
        data: {
          color: "teal lighten-5",
          icon: "people",
          iconColor: "teal",
          text: this.computeFilterText(),
          inputs: {
            from: this.form.from,
            to: this.isToDisabled ? "" : this.form.to
          }
        }
      })
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== this.moduleName || e.detail.item.type !== filterName) return

      // for cases of paid collaborations, make sure to only remove the correct one
      if (this.moduleId && this.moduleId !== e.detail.moduleId) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== this.moduleName || e.detail.item.type !== filterName) return

      // for cases of paid collaborations, make sure to only add the correct one
      if (this.moduleId && this.moduleId !== e.detail.moduleId) return

      // now that it is the one we want to see, continue
      this.form = e.detail.item.data.inputs
    })
  }
}
</script>
