<template>
  <filter-followers-block
    module-name="influencerDiscovery"
    :is-filter-applied="isFilterApplied"
    :max-value="10000000"
    @submit="handleSubmit"
  />
</template>

<script>
const FilterFollowersBlock = () => import(/* webpackChunkName: "filter-followers" */ "@/blocks/common/filters/FilterFollowers.vue")

export default {
  name: "FilterFollowers",

  components: {
    FilterFollowersBlock
  },

  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters[`influencerDiscovery/findFiltersByType`]("followers").length)
    }
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch(`influencerDiscovery/replaceFilter`, data)
    }
  }
}
</script>
